import * as React from "react";

export default function Home() {
  return (
    <>
      <header className="u-clearfix u-header" id="sec-b9d0">
        <div className="u-clearfix u-sheet u-sheet-1">
          <h2 className="u-text u-text-default u-text-1">Nexiflux</h2>
        </div>
      </header>
      <section
        className="u-align-center u-clearfix u-image u-shading u-section-1"
        data-image-width="256"
        data-image-height="256"
        id="sec-0eb6"
      >
        <div className="u-clearfix u-sheet u-sheet-1">
          <h1 className="u-text u-text-default u-title u-text-1">
            Under construction
          </h1>
          <p className="u-custom-font u-heading-font u-large-text u-text u-text-default u-text-variant u-text-2">
            Our site is not ready yet{" "}
          </p>
        </div>
      </section>
    </>
  );
}
