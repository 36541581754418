import React from "react";
import Home from "./pages/home";

import "./Home.css";
import "./nicepage.css";

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
